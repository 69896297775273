import { Controller } from "stimulus"
import { cocoonInitializer } from 'cocoon/app/assets/javascripts/cocoon'

export default class extends Controller {
  static targets = ["activityIconContainer", "activityIconUncompleted", "activityIconCompleted", "activityPath" ]

  toggleActivityIcon(event) {
    console.log("user_activities_controller toggleActivityIcon");
    let iconContainer = this.activityIconContainerTarget
    iconContainer.classList.remove("futur")
    iconContainer.classList.add("past")

    this.activityIconUncompletedTarget.classList.add("d-none")
    this.activityIconCompletedTarget.classList.remove("d-none")
  }

  toggleActivityPath(event) {
    this.activityPathTarget.href = this.completedPath
  }

  get completedPath() {
    return this.data.get("completed-path");
  }


  connect() {
  }
}