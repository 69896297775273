import { Controller } from 'stimulus'
import { submitForm } from '../components/form_actions'

export default class extends Controller {
  static targets = ["agencyFilter", "referentFilter"]

  submitForm = submitForm

  submitWithBatchFilter(event) {
    this.agencyFilterTarget.value = ''
    this.referentFilterTarget.value = ''
    submitForm(event)
  }

  submitWithAgencyFilter(event) {
    this.referentFilterTarget.value = ''
    submitForm(event)
  }
}
