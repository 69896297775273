import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "destination" ]

  renderChart() {
    const resultData = JSON.parse(this.chartData)
    const ctx = this.destinationTarget;
    const data = {
      labels: ['Réaliste', 'Investigateur', 'Artiste', 'Social', 'Entreprenant', 'Conventionnel'],
      datasets: [{
        data: [resultData.R, resultData.I, resultData.A, resultData.S, resultData.E, resultData.C],
        borderColor: 'rgba(54, 35, 139, 1)',
        backgroundColor: 'rgba(54, 35, 139, 0.2)',
        pointBackgroundColor: 'rgba(54, 35, 139, 1)',
        pointBorderColor: 'rgba(54, 35, 139, 1)',
        pointRadius: 5
      }]
    }
    const options = {
      scale: {
        ticks: {
          beginAtZero: true,
          max: 15,
          stepSize: 1,
          fontColor: 'rgba(219, 79, 48, 0.5)'
        },
        gridLines: {
          color: 'rgba(219, 79, 48, 0.2)',
          lineWidth: 2
        },
        angleLines: {
          color: 'rgba(219, 79, 48, 0.2)',
          lineWidth: 2
        },
        pointLabels: {
          fontColor: '#1b8485',
          fontSize: 16,
          fontStyle: 'bold'
        }
      },
      tooltips: {
        callbacks: {
          label: function(tooltipItem, data) {
            const label = tooltipItem.yLabel;
            return label;
          }
        }
      },
      legend: {
        display: false
      }
    }
    const myRadarChart = new Chart(ctx, {
      type: 'radar',
      data: data,
      options: options
    });
  }

  get chartData() {
    return this.data.get("data");
  }

  
  initialize() {
    console.log("initialize chart controller")
    this.renderChart()
  }

  connect() {
    console.log("connect chart controller")
  }
}