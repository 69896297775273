/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

require("utils")

import Rails from 'rails-ujs';
import Turbolinks from 'turbolinks';
import * as ActiveStorage from 'activestorage';
import Chart from 'chart.js';

import 'bootstrap/dist/js/bootstrap';
import "actiontext"
import "controllers"

// A decommenter si on l'utilise ? Actioncable tjs présent dans assets/javascript. Faut voir ce qu'on en fait
// import ActionCable from 'actioncable'
// window.Cable = ActionCable.createConsumer()

Rails.start();
Turbolinks.start();
ActiveStorage.start();

console.log('Hello World from Webpacker')

//Hide password option
$(document).on('turbolinks:load', function() {
  $("#show_hide_password a").on('click', function(event) {
    event.preventDefault();
    if ($('#show_hide_password input').attr("type") == "text"){
      $('#show_hide_password input').attr('type', 'password');
      $('#show_hide_password i').addClass( "fa-eye-slash" );
      $('#show_hide_password i').removeClass( "fa-eye" );
    }else if ($('#show_hide_password input').attr("type") == "password"){
      $('#show_hide_password input').attr('type', 'text');
      $('#show_hide_password i').removeClass( "fa-eye-slash" );
      $('#show_hide_password i').addClass( "fa-eye" );
    }
  });
});

//Carousel
$(document).on('turbolinks:load', function() {
  $('#carouselWorkshops').on('slide.bs.carousel', function (e) {

    var $e = $(e.relatedTarget);
    var idx = $e.index();
    var itemsPerSlide = 3;
    var totalItems = $('.carousel-item').length;

    if (idx >= totalItems-(itemsPerSlide-1)) {
      var it = itemsPerSlide - (totalItems - idx);
      for (var i=0; i<it; i++) {
        // append slides to end
        if (e.direction=="left") {
            $('.carousel-item').eq(i).appendTo('.carousel-inner');
        }
        else {
            $('.carousel-item').eq(0).appendTo('.carousel-inner');
        }
      }
    }
  });
});

import * as Trix from "trix";

// Replacing the whole lang object as advised in
// https://github.com/basecamp/trix/wiki/I18n
// doesn't seem to work
// so we set each property one by one
Trix.config.lang.bold = "Gras"
Trix.config.lang.bullets = "Liste"
Trix.config.lang.byte =  "Octet"
Trix.config.lang.bytes = "Octets"
Trix.config.lang.captionPlaceholder = "Ajouter légende…"
Trix.config.lang.code = "Code"
Trix.config.lang.heading1 = "Titre"
Trix.config.lang.indent = "Agrandir retrait"
Trix.config.lang.italic = "Cursif"
Trix.config.lang.link = "Lien"
Trix.config.lang.numbers = "Énumération"
Trix.config.lang.outdent = "Réduire retrait"
Trix.config.lang.quote = "Citation"
Trix.config.lang.redo = "Refaire"
Trix.config.lang.remove = "Supprimer"
Trix.config.lang.strike = "Biffé"
Trix.config.lang.undo = "Annuler"
Trix.config.lang.unlink = "Supprimer lien"
Trix.config.lang.url = "URL"
Trix.config.lang.urlPlaceholder = "Saisir URL…"
Trix.config.lang.GB = "Go"
Trix.config.lang.KB = "Ko"
Trix.config.lang.MB = "Mo"
Trix.config.lang.PB = "Po"
Trix.config.lang.TB = "To"
// Trix.config.lang = {
//   bold: "Gras",
//   bullets: "Liste",
//   "byte":  "Octet",
//   bytes: "Octets",
//   captionPlaceholder: "Ajouter légende…",
//   code: "Code",
//   heading1: "Titre",
//   indent: "Agrandir retrait",
//   italic: "Cursif",
//   link: "Lien",
//   numbers: "Énumération",
//   outdent: "Réduire retrait",
//   quote: "Citation",
//   redo: "Refaire",
//   remove: "Supprimer",
//   strike: "Biffé",
//   undo: "Annuler",
//   unlink: "Supprimer lien",
//   url: "URL",
//   urlPlaceholder: "Saisir URL…",
//   GB: "Go",
//   KB: "Ko",
//   MB: "Mo",
//   PB: "Po",
//   TB: "To",
// }
