import { Controller } from "stimulus"

const checkButton = (button) => {
  var checkedLabel = button.closest('.carousel-caption').querySelector('.js-selected-label')
  checkedLabel.style.display = "inline-block"
  button.style.display = "none"
}

const unchekButton = (button) => {
  var checkedLabel = button.closest('.carousel-caption').querySelector('.js-selected-label')
  checkedLabel.style.display = "none"
  button.style.display = "inline-block"
}

const unselectAllButtons = (tab) => {
  var buttons = tab.getElementsByClassName('js-select-button')
  Array.from(buttons).forEach(button => unchekButton(button))
}

const displayFields = (tab) => {
  tab.querySelector('.fields').style.display = "block"
}

const setValueToHiddenField = (tab, val) => {
  tab.querySelector('.js-image-url-field').value = val
}

const manageNextButtonState = (tab, submitButton) => {
  if (allFieldsFilled()) {
    submitButton.disabled = false
  }
}

const allFieldsFilled = () => {
  var fields = document.getElementsByClassName('js-image-url-field')
  var defaultResponse = true
  Array.from(fields).forEach((field) => {
    if (field.value === "") {
      defaultResponse = false
    }
  })
  return defaultResponse
}

export default class extends Controller {

  static targets = ['submitButton']

  selectPicture (event) {
    var selectButton = event.currentTarget
    var currentTab = selectButton.closest('.tab-pane')

    unselectAllButtons(currentTab)
    checkButton(selectButton)
    displayFields(currentTab)
    setValueToHiddenField(currentTab, selectButton.dataset.val)
    manageNextButtonState(currentTab, this.submitButtonTarget)
  }

  connect() {
    $('.carousel').carousel('pause')
  }
}
