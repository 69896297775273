// Controller de l'activité Ancres de Carrière
// Hérite de question slider controller

import QuestionSliderController from "../question_slider_controller"

const htmlToNode = (html) => {
  return document.createRange().createContextualFragment(html)
}


export default class extends QuestionSliderController {
  static targets = [ "completeActivityButton", "indexProgress", "template", "list", "errorMessage"]

  next(event) {
    event.preventDefault()
    this.buildBonusForm()
    this.index++
  }

  manageNavigation() {
    // Si aucune proposition cochée, on disable le bouton Suivant
    this.manageNextButtonState()
    // Manage navigation button display
    this.previousButtonTarget.classList.toggle('d-none', this.index == 0 )
  }

  manageNextButtonState() {
    if (this.hasNoCheckedProposal()) {
      this.disableElement(this.nextButtonTarget)
      this.disableElement(this.completeActivityButtonTarget)
    } else {
      this.enableElement(this.nextButtonTarget)
      this.enableElement(this.completeActivityButtonTarget)
    }
  }

  refreshIndexDisplay() {
    this.indexTarget.textContent = this.index + 1
    // refresh progress bar
    const percentage = (this.index+1) / this.size *100
    this.indexProgressTarget.setAttribute("aria-valuenow", percentage)
    this.indexProgressTarget.style.width = `${percentage}%`;

  }

  manageCompletion() {
    this.nextButtonTarget.classList.toggle('d-none', this.isCompleted())
    this.completeActivityButtonTarget.classList.toggle('d-none', !this.isCompleted())
  }

  // Controller specific methods --------------------

  hasNoCheckedProposal() {
    const checkedInputs = this.currentBlock.querySelectorAll('input[type="radio"]:checked').length
    return checkedInputs == 0
  }

  get size() {
    return parseInt(this.data.get("size"))
  }

  buildBonusForm() {
    document.querySelectorAll('.js-anchor-radio:checked').forEach(input => this.addStatementCheckbox(input))
  }

  addStatementCheckbox(input) {
    if (input.value == '5') {
      var template = this.templateTarget.innerHTML
      var template = template.replace(/anchor_id/g, input.dataset.id)
      var template = template.replace(/anchor_statement/g, input.dataset.statement)
      var templateId = htmlToNode(template).querySelector('input').id
      if (document.getElementById(templateId) == null) {
        this.listTarget.insertAdjacentHTML('beforebegin', template)
      }
    }
  }

  enableSubmit() {
    if (document.querySelectorAll('.js-bonus-checkbox:checked').length == 3) {
      this.enableElement(this.completeActivityButtonTarget)
      this.errorMessageTarget.textContent = ""
    } else {
      this.disableElement(this.completeActivityButtonTarget)
      this.errorMessageTarget.textContent = "Veuillez choisir 3 propositions"
    }
  }
}
