import { Controller } from 'stimulus'

const toggleVisbility = (element) => {
  if (element.classList.contains('hidden')) {
    element.classList.remove('hidden');
  } else {
    element.classList.add('hidden');
  }
}

export default class extends Controller {

  static targets = ["nextEvent"]

  displayAllEvents(event) {
    Array.from(event.currentTarget.children).forEach(element => toggleVisbility(element))
    toggleVisbility(this.nextEventTarget)
  }
}
