// Controller de l'activité Riasec
// Hérite de question slider controller

import QuestionSliderController from "../question_slider_controller"

export default class extends QuestionSliderController {
  static targets = [ "completeActivityButton", "indexProgress" ]

  manageNavigation() {
    console.log('manageNavigation RIASEC')
    // Si aucune proposition de cocher, on disable le bouton Suivant
    this.manageNextButtonState()
    // Manage navigation button display
    this.previousButtonTarget.classList.toggle('d-none', this.index == 0 )
  }

  manageNextButtonState() {
    if (this.hasNoCheckedProposal()) {
      this.disableElement(this.nextButtonTarget)
      this.disableElement(this.completeActivityButtonTarget)
    } else {
      this.enableElement(this.nextButtonTarget)
      this.enableElement(this.completeActivityButtonTarget)
    }
  }

  refreshIndexDisplay() {
    this.indexTarget.textContent = this.index + 1
    // refresh progress bar
    const percentage = (this.index+1) / this.size *100
    this.indexProgressTarget.setAttribute("aria-valuenow", percentage)
    this.indexProgressTarget.style.width = `${percentage}%`;

  }

  manageCompletion() {
    this.nextButtonTarget.classList.toggle('d-none', this.isCompleted())
    this.completeActivityButtonTarget.classList.toggle('d-none', !this.isCompleted())
  }

  // Controller specific methods --------------------

  hasNoCheckedProposal() {
    const checkedInputs = this.currentBlock.querySelectorAll('input[type="radio"]:checked').length
    return checkedInputs == 0
  }

  get size() {
    return parseInt(this.data.get("size"))
  }

}