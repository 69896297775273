import { Controller } from "stimulus"

export default class extends Controller {

  openModal(event) {
    event.preventDefault()
    $('#mainModal').modal()
  }

  connect() {
    const mainModal = $('#mainModal')
    if (mainModal && localStorage.getItem('covidModal') == null ) {
      mainModal.modal()
      localStorage.setItem('covidModal','displayed')
    }
  }
}
