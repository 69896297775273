import { Controller } from "stimulus"

const getParam = (key) => {
  var url = new URL(window.location.href)
  return url.searchParams.get(key)
}

export default class extends Controller {
  static targets = [ "modal", "basicCounter", "workshopCounter", "summarySheetCounter", "themeCard" ]

  openModal(event) {
    event.preventDefault()
    console.log("openModal - user_THEMES_controller");
    const location = event.target.href;
    const modal_selector = '.modal';

    $.get(
      location,
      data => {
        const modalAlreadyOpened = ($(this.modalController.element).find(modal_selector).data('bs.modal') || {})._isShown
        console.log(data)
        this.modalController.render(data);
        // check if modal already opened
        if (!modalAlreadyOpened) {
          this.modalController.openModal()
        }
      }
    );

    return false;
  }

  manageActivityCompletion(event) {
    console.log("manageActivityCompletion - user_THEMES_controller");
    console.log(event)
    let detail = event.detail

    this.toggleActivity(detail)
    this.updateCounters(detail)
    this.completeTheme(detail)
  }

  toggleActivity(event_detail) {
    console.log("toggleActivity - user_THEMES_controller");
    let selector = document.querySelector(`[data-user-activities-activity-id="${event_detail.user_activity.activity_id}"]`)
    let activityController = this.application.getControllerForElementAndIdentifier(selector, 'user-activities')
    activityController.toggleActivityIcon()
    activityController.toggleActivityPath()
  }

  updateCounters(event_detail) {
    console.log("updateCounters - user_THEMES_controller");
    this.basicCounterTarget.innerHTML = event_detail.basic_counter;
    this.workshopCounterTarget.innerHTML = event_detail.workshop_counter;
    this.summarySheetCounterTarget.innerHTML = event_detail.summary_sheet_counter;
  }

  completeTheme(event_detail) {
    console.log("completeTheme - user_THEMES_controller");
    if (event_detail.theme_completed == true) {
      this.themeCardTarget.classList.add("completed")
    }
  }

  get modalController() {
    return this.application.getControllerForElementAndIdentifier(
            this.modalTarget,
            "user-activity-modals"
            )
  }

  connect() {
    var themeId = getParam('theme_id')
    var element = document.getElementById(`collapse${themeId}`)
    if (element) {
      element.scrollIntoView(false)
    }
  }
}
