import { Controller } from "stimulus"

export default class extends Controller {

  toggleFileInput() {
    const fileElements = document.querySelectorAll('.form-control-file');
    fileElements.forEach(function(element){
      element.style.display = element.style.display==='none' ? 'block' : 'none';
    });
  }
}
