import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['renderBlock']

  completeActivity(event) {
    console.log("user_activity_modals_controller completeActivity");
    let [data, status, xhr] = event.detail;
    console.log(data)
      
    if (data.completed == true) {
      // dispatch custom event to warn parent theme controller
      let customEvent = new CustomEvent('activity-completed', { bubbles: true, detail: data.data });
      this.element.dispatchEvent(customEvent)

      this.render(data.html)
    }

    return false;
  }

  render(html) {
    this.renderBlockTarget.innerHTML = html
    // on a des loooongues modal
    $(this.renderBlockTarget).animate({ scrollTop: 0 }, "slow");
  }

  closeModal() {
    $(this.renderBlockTarget).modal('hide')
  }

  openModal() {
    $(this.renderBlockTarget).modal()
  }

  connect() {
  }
}