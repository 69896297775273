// Controller des dimensions de l'activité Roue des savoir-être

import QuestionSliderController from "../question_slider_controller"

export default class extends QuestionSliderController {
  static targets = [ "title" ]


  manageNavigation() {
    this.previousButtonTarget.classList.toggle('d-none', (this.index == 0 || this.isCompleted() ))
    this.nextButtonTarget.classList.toggle('d-none', this.isCompleted())
    // Manage next button text
    if ((this.index+2) == this.blockTargets.length) {
      this.nextButtonTarget.textContent = "Valider"
    } else {
      this.nextButtonTarget.textContent = "Suivant"
    }
  }

  manageCompletion() {
    if (this.isCompleted()) {
      let customEvent = new CustomEvent('dimension-completed', { bubbles: true, detail: this.currentQuestionTitle });
      this.element.dispatchEvent(customEvent)
    }
  }

  refreshDisplay() {
    this.refreshIndexDisplay()
    this.refreshTitleDisplay()
  }

  refreshIndexDisplay() {
    if (this.index + 1 != this.blockTargets.length) {
      this.indexTarget.textContent = this.index + 1 
    }
  }

  refreshTitleDisplay() {
    console.log('refreshTitleDisplay')
    console.log(this.currentQuestionTitle)
    this.titleTarget.textContent = this.currentQuestionTitle
  }

  get currentQuestionTitle() {
    return this.blockTargets[this.index].dataset.title
  }

  connect() {
    console.log("roue des savoir dimension connect")
  }
}