import { Controller } from "stimulus"

const show = (element) => {
  element.classList.remove('hidden')
}

const hide = (element) => {
  element.classList.add('hidden')
}

const displayFields = (partial) => {
  var input = partial.getElementsByClassName('js-job-input')[0]
  var title = partial.getElementsByClassName('js-job-title')[0]
  var commentField = partial.getElementsByClassName('js-job-comment')[0]

  if (input.value != '') {
    title.innerHTML = input.value
    show(title)
    hide(input)
    show(commentField)
  } else {
    hide(partial)
  }
}

export default class extends Controller {

  displayHiddenFields(event) {
    document.querySelectorAll('.js-job-partial').forEach(partial => displayFields(partial))
    var submitButton = document.getElementsByClassName('js-submit')[0]
    show(submitButton)
    hide(event.currentTarget)
  }

  displayNextButton(event) {
    var nextButton = document.getElementsByClassName('js-next-button')[0]
    if (event.currentTarget.value != '') {
      show(nextButton)
    } else {
      hide(nextButton)
    }
  }
}
