import { Controller } from "stimulus"

const reinitActiveTab = () => {
  $('.tab-pane').removeClass('active show')
  $('.nav-item').removeClass('active')
}

const setIdAndClass = (form, index) => {
  form.attr('id',`nav-${index}`)
  form.addClass('active show')
}

const addNewTab = (tab) => {
  $('.add_fields').before(tab)
}

export default class extends Controller {
  connect() {
    $('#nav-tabContent')
      .on('cocoon:before-insert', function(e, form) {
        if (form.hasClass('tab-pane')) {
          var tabsContainer = $('#nav-tab')
          var formsContainer = $('#nav-tabContent')
          var tabIndex = formsContainer.children().length
          var newTab = `<a class='nav-item nav-link text-secondary active' data-toggle='tab' href='#nav-${tabIndex}' id='nav-${tabIndex}-tab' role='tab'>${tabsContainer.data("newTabLabel")}</a>`

          reinitActiveTab()

          addNewTab(newTab)
          setIdAndClass(form, tabIndex)
        }
      })
      .on("cocoon:after-remove", function(e, form) {
        if (form.hasClass('tab-pane')) {
          var tabId = form.attr('id')
          $(`#${tabId}-tab`).remove()
          $('.nav-item').last().addClass('active')
          $('.tab-pane:not([style*="display: none"])').last().addClass('active show')
        }
      })
  }
}
