// Controller de l'activité Roue des savoir-être

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "dimension", "completeActivityButton" ]

  get dimensionControllers() {
    let dimensionsArray = []
    this.dimensionTargets.forEach((el, i) => {
      let dimensionController = this.application.getControllerForElementAndIdentifier(el, 'activity--roue-dimension')
      dimensionsArray.push(dimensionController)
    })
    return dimensionsArray
  }

  dimensionsAllCompleted() {
    const callback = (accumulator, currentValue) =>{ 
      return currentValue == false ? currentValue : accumulator
    };
    return this.dimensionControllers.map( controller => controller.isCompleted() ).reduce(callback, true);
  }

  manageDimensionCompletion(event) {
    console.log("manageDimensionCompletion")
    if (this.dimensionsAllCompleted()) {
      this.unlockCompleteActivityButton()
    }
  }

  unlockCompleteActivityButton() {
    this.completeActivityButtonTarget.classList.remove('disabled')
    this.completeActivityButtonTarget.classList.remove('btn-fantom')
    this.completeActivityButtonTarget.classList.add('btn-success')
  }

  initialize() {
    console.log("initialize roue des savoir")
  }


  connect() {
    console.log("roue des savoir connect")
    // console.log(this.dimensionTargets)
    // console.log(this.dimensionControllers)
  }
}